import styled from "styled-components"
import { Box, Header, Flex } from "src/styled"
import { devices } from "src/theme/device"

export const Form = styled.form`
    width: 100%;
    padding: 0 50px;
    ${devices.tablet} {
        padding: 0;
    }
`

export const SettingList = styled.ul`
    list-style-type: none;
    background: ${({ theme }) => theme.colors.lightGray};
    padding: 20px 20px 20px 100px;
    border-radius: 5px;
    height: fit-content;
    margin-left: -20px;
    min-width: fit-content;
    ${devices.tablet} {
        display: none;
    }
`

export const SettingItem = styled.li`
    font-size: 20px;
    padding: 5px 0;
    color: ${({ theme, isActive }) =>
        isActive ? theme.colors.primary : theme.colors.primaryDark};
    cursor: pointer;
`

export const Wrapper = styled(Flex)`
    margin: 25px 0;
    max-width: 100%;

    ${devices.tablet} {
        margin: 10px 0;
        flex-direction: column;
    }
`

export const Container = styled(Box)`
    margin: 25px 0;
    padding: 20px;

    ${devices.tablet} {
        margin: 0;
    }
`

export const StyledHeader = styled(Header)`
    font-size: 40px;
    margin-left: 100px;

    ${devices.tablet} {
        // margin: 0 auto;
        // text-align: center;
        display: none;
    }
`

export const Dropdown = styled.ul`
    color: red;
    display: none;
    list-style-type: none;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    ${devices.tablet} {
        display: initial;
        text-align: center;
        margin: 0 auto;
        justify-content: center;
    }

    overflow: hidden;
    position: relative;
`

export const DropdownAction = styled.li`
    padding: 5px 0;
    font-size: 16px;
    color: ${({ theme, isActive }) =>
        isActive ? theme.colors.primary : theme.colors.primaryDark};

    transition: 0.1s ease-in;
    &:hover {
        background: ${({ theme }) => theme.colors.lightGray};
    }

    svg {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 24px;
        position: absolute;
        right: 10px;
        transform: ${({ active }) => (active ? "rotateZ(90deg)" : "")};
        transition: 0.2s ease-in;
    }
`

export const DropdownItem = styled.li`
    padding: 5px 0;
    font-size: 16px;
    color: ${({ theme, isActive }) =>
        isActive ? theme.colors.primary : theme.colors.primaryDark};

    transition: 0.1s ease-in;
    &:hover {
        background: ${({ theme }) => theme.colors.lightGray};
    }
`

export const DropdownWrapper = styled(Flex)`
    overflow: hidden;
    flex-direction: column;
    height: fit-content;
    transition: 0.2s ease-in;
    height: ${({ active }) => (active ? "fit-content" : 0)};
`
