import React from "react"
import { Form } from "./components"
import { Input, Loading } from "src/components/common"
import { Button } from "src/styled"
import { useSelector, useDispatch } from "react-redux"
import { setUserData, updateUserData } from "src/redux/user"

function ProfileForm() {
    const dispatch = useDispatch()
    const { userData, isUpadingUserData } = useSelector(state => state.user)

    function handleSubmit(event) {
        event.preventDefault()
        dispatch(updateUserData())
    }

    function handleChange(e) {
        dispatch(setUserData({ [e.currentTarget.name]: e.currentTarget.value }))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                label="Nazwa"
                name="name"
                value={userData.name}
                onChange={handleChange}
            />

            <Input
                label="Mail"
                name="email"
                value={userData.email}
                onChange={handleChange}
            />

            <Input
                name="phoneNumber"
                label="Numer telefonu"
                value={userData.phoneNumber}
                onChange={handleChange}
            />

            <Input
                name="address"
                label="Adres dostawy"
                value={userData.address}
                onChange={handleChange}
            />

            <Input
                label="NIP"
                name="nip"
                value={userData.nip}
                onChange={handleChange}
            />
            <Button variant={"primary-wide"} type="submit">
                {isUpadingUserData ? <Loading color="white" /> : "Zmień"}
            </Button>
        </Form>
    )
}

export default ProfileForm
