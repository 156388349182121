import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Modal, Loading } from "src/components/common"
import { devices } from "src/theme/device"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserOrders } from "src/redux/user"
import formatDate from "src/utils/formatDate"
import emptyImg from "src/assets/images/empty_state.png"
import { Box } from "src/styled"
import { Form } from "./components"
import { Link } from "@reach/router"
import { setItems } from "src/redux/basket"

const Image = styled.img.attrs({ src: `${emptyImg}` })`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;

    ${devices.tablet} {
        width: 90%;
        margin: auto;
        height: 300px;
    }
`

const Table = styled.table`
    flex: 1;
    text-align: left;
    margin: auto;
    width: calc(65% - 20px);
    border-collapse: collapse;
    th {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
        padding: 5px 0;
    }

    #link {
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }

    th,
    td {
        padding: 10px 20px;
        border-spacing: 0;
    }

    tr,
    p {
        *:last-child {
            text-align: right;
        }
    }

    #info {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
        font-weight: bold;
    }

    ${devices.tablet} {
        font-size: 14px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    border-collapse: collapse;

    p {
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
    }
`

function ProfileForm() {
    const [modalActive, setModalActive] = useState(false)
    const dispatch = useDispatch()
    const [previewingOrder, setPreviewingOrder] = useState(undefined)
    const { isFetchingUserOrders, userOrders } = useSelector(
        state => state.user
    )

    function previewOrder(order) {
        setModalActive(true)
        setPreviewingOrder(order)
    }

    useEffect(() => {
        const getOrders = () => {
            dispatch(fetchUserOrders())
        }
        getOrders()
    }, [])

    if (isFetchingUserOrders) {
        return <Loading />
    }

    function batchItems(orders) {
        return orders.reduce((all, current) => {
            const index = all.findIndex(e => e.id === current.id)
            index > 0
                ? all[index].quantity++
                : all.push({ quantity: 1, ...current })
            return all
        }, [])
    }

    function getTotalCost(items) {
        return items.reduce(
            (total, item) => (total += item.price * item.quantity),
            0
        )
    }

    function quantityOfOrders(orders) {
        const ordersWithQuantity = orders.reduce((acc, item) => {
            let existItem = acc.find(({ id }) => item.id === id)
            if (existItem) {
                existItem.quantity += 1
            } else {
                acc.push({ ...item, quantity: 1 })
            }
            return acc
        }, [])
        return ordersWithQuantity
    }

    function toStatusPL(status) {
        if (status === "confirmed") {
            return "potwierdzone"
        } else if (status === "new") {
            return "nowe"
        } else if (status === "canceled") {
            return "anulowane"
        }
    }

    return (
        <>
            {userOrders && userOrders.length < 1 ? (
                <Wrapper>
                    <Image />
                    <p>
                        {" "}
                        {`Nie wykonałeś jeszcze zamówień na naszej stronie ;)`}
                    </p>
                </Wrapper>
            ) : (
                <Table>
                    <tr>
                        <th>ID zamówienia</th>
                        <th>Data</th>
                        <th>Kwota</th>
                        <th>Status</th>
                        <th>Działanie</th>
                    </tr>

                    {userOrders.map(order => (
                        <tr>
                            <td>{order.id}</td>
                            <td>
                                {formatDate(new Date(order.BWT.date))}{" "}
                                {order.BWT.selectedHour}
                                :00
                            </td>
                            <td>{order.price} PLN</td>
                            <td>{toStatusPL(order.lastStatus)}</td>
                            <td
                                id="link"
                                onClick={() =>
                                    dispatch(
                                        setItems(quantityOfOrders(order.orders))
                                    )
                                }
                            >
                                <Link
                                    to="/basket"
                                    state={{
                                        repeated: true,
                                        orderDate: order.BWT.date,
                                    }}
                                >
                                    SZCZEGÓŁY
                                </Link>
                            </td>
                        </tr>
                    ))}
                </Table>
            )}
        </>
    )
}

export default ProfileForm
