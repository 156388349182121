//@ts-check
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import ProfileForm from "src/modules/user/ProfileForm"
import HistoryForm from "src/modules/user/HistoryForm"
import { userPanel } from "src/constants"
import {
    SettingList,
    SettingItem,
    Wrapper,
    Container,
    StyledHeader,
    Dropdown,
    DropdownItem,
    DropdownAction,
    DropdownWrapper,
} from "src/modules/user/components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import withAuth from "src/hoc/withAuth"
import { signOut } from "src/redux/user"

const Profile = props => {
    const [currentTab, setCurrentTab] = useState(userPanel.profile)
    const [activeDropdown, setActiveDropdown] = useState(false)
    const dispatch = useDispatch()

    function renderTab(tab) {
        const options = {
            [userPanel.history]: <HistoryForm />,
            [userPanel.profile]: <ProfileForm />,
        }

        return options[tab]
    }

    function setTab(tab) {
        setCurrentTab(tab)
        setActiveDropdown(false)
    }

    function getActiveTabName() {
        const options = {
            [userPanel.history]: "Historia zamówień",
            [userPanel.profile]: "Moje dane",
        }
        return options[currentTab]
    }

    return (
        <Container>
            <StyledHeader>Ustawienia konta</StyledHeader>

            <Wrapper>
                {/* For desktop */}
                <SettingList>
                    <SettingItem
                        isActive={currentTab === userPanel.profile}
                        onClick={() => setTab(userPanel.profile)}
                    >
                        Moje dane
                    </SettingItem>

                    <SettingItem
                        isActive={currentTab === userPanel.history}
                        onClick={() => setTab(userPanel.history)}
                    >
                        Historia zamówień
                    </SettingItem>

                    <SettingItem onClick={() => dispatch(signOut())}>
                        Wyloguj się
                    </SettingItem>
                </SettingList>

                {/* For mobile */}
                <Dropdown>
                    <DropdownAction
                        active={activeDropdown}
                        onClick={() => setActiveDropdown(!activeDropdown)}
                    >
                        {!activeDropdown
                            ? getActiveTabName()
                            : "Ustawienia konta"}
                        <FontAwesomeIcon icon={faAngleRight} />
                    </DropdownAction>

                    <DropdownWrapper active={activeDropdown}>
                        <DropdownItem
                            isActive={currentTab === userPanel.profile}
                            onClick={() => setTab(userPanel.profile)}
                        >
                            Moje dane
                        </DropdownItem>

                        <DropdownItem
                            isActive={currentTab === userPanel.history}
                            onClick={() => setTab(userPanel.history)}
                        >
                            Historia zamówień
                        </DropdownItem>

                        <DropdownItem
                            isActive={currentTab === userPanel.history}
                            onClick={() => dispatch(signOut())}
                        >
                            Wyloguj się
                        </DropdownItem>
                    </DropdownWrapper>
                </Dropdown>

                {renderTab(currentTab)}
            </Wrapper>
        </Container>
    )
}

export default withAuth(Profile, true, "/verify")
